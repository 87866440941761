import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandFonts,
  fontWeights,
  fluidFontSize,
} from '../styles';
import { Container, Button, Wave } from '../components/ui';

const StyledBanner = styled.section`
  position: relative;
  ${sectionPaddings('60px', '140px')};
`;

const StyledImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${standardColours.black};
`;

const StyledImage = styled(GatsbyImage)`
  height: 100%;
  opacity: 0.6;
`;

const StyledContentWrapper = styled.div`
  position: relative;
  text-align: center;
`;

const StyledWave = styled(Wave)`
  stroke: ${standardColours.white};
`;

const StyledHeading = styled.h1`
  margin: 20px 0 30px;
  color: ${standardColours.white};
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  ${fluidFontSize('45px', '85px', breakpointSizes.tiny, breakpointSizes.large)};
  line-height: 1.1;
  white-space: break-spaces;

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${fluidFontSize(
          '45px',
          '85px',
          breakpointSizes.tiny,
          breakpointSizes.large
        )};
      `;
    } else {
      return css`
        ${fluidFontSize(
          '38px',
          '70px',
          breakpointSizes.tiny,
          breakpointSizes.large
        )};
      `;
    }
  }}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-bottom: 40px;
  `}
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;

  ${maxBreakpointQuery.small`
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
  `}
`;

const StyledButton = styled(Button)`
  color: ${standardColours.white};
  border-color: ${standardColours.white};

  svg {
    fill: ${standardColours.white};
  }

  &:nth-child(2) {
    ${maxBreakpointQuery.small`
      margin-top: 20px;
    `}

    ${minBreakpointQuery.small`
      margin-left: 20px;
    `}

    ${minBreakpointQuery.medium`
      margin-left: 30px;
    `}

    ${minBreakpointQuery.large`
      margin-left: 40px;
    `}
  }
`;

const Banner = ({
  image: { gatsbyImageData, alt },
  heading,
  links,
  isHomepage,
}) => (
  <StyledBanner>
    <StyledImageWrapper>
      <StyledImage image={gatsbyImageData} alt={alt} loading="eager" />
    </StyledImageWrapper>
    <StyledContentWrapper>
      <Container>
        <StyledWave />
        <StyledHeading isHomepage={isHomepage}>{heading}</StyledHeading>
        {links.length > 0 && (
          <StyledButtons>
            {links.map(({ id, text, page }) => (
              <StyledButton key={id} to={page.slug} linkData={page}>
                {text}
              </StyledButton>
            ))}
          </StyledButtons>
        )}
      </Container>
    </StyledContentWrapper>
  </StyledBanner>
);

export default Banner;
