import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import PageHeader from '../components/PageHeader';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: {
    datoCmsPage: {
      seoMetaTags,
      title,
      bannerImage,
      bannerLinks,
      modularBlocks,
    },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      {bannerImage ? (
        <Banner image={bannerImage} heading={title} links={bannerLinks} />
      ) : (
        <PageHeader heading={title} />
      )}
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        gatsbyImageData(width: 1400, height: 500, layout: FULL_WIDTH)
        alt
      }
      bannerLinks {
        id
        text
        page {
          ...LinkFragment
        }
      }
      modularBlocks {
        ...AccordionModularBlockFragment
        ...CompanyReviewsModularBlockFragment
        ...ContactFormModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedProjectsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...HighlightedTextModularBlockFragment
        ...IconCtasModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...ImageLinkCardsModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...TabsModularBlockFragment
        ...TeamProfilesModularBlockFragment
        ...TestimonialsModularBlockFragment
      }
    }
  }
`;

export default DefaultPageTemplate;
